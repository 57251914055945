const Swiper = require("swiper");

window.addEventListener("load", init);

gsap.registerPlugin(ScrollTrigger);

// gsap.set(".negocio .col-img img", {
//   x: 200,
// });

const negocio = document.querySelector(".negocio");

if (negocio) {
  const btnAgri = document.querySelector(".nav-item-agri");
  const btnPecu = document.querySelector(".nav-item-pecu");

  // const colTxt = document.querySelector(".col-txt");
  // const colImg = document.querySelector(".col-img");

  btnAgri.addEventListener("click", e => {
    e.preventDefault();
    tl.reverse();
  });

  btnPecu.addEventListener("click", e => {
    e.preventDefault();
    tl.play();
  });

  gsap.set(".text-pecu", {
    opacity: 0,
  });
  gsap.set(".nav-item-agri", {
    color: "#225539",
  });
  let tl = gsap.timeline({
    duration: 0.05,
    // scrollTrigger: {
    //   trigger: ".pill",
    //   start: "center center",
    //   // markers: true,
    //   toggleActions: "restart none none reverse",
    // },
  });

  tl.to(".pill", { x: 128 }).to(".text-agri", { opacity: 0 }, "<").to(".text-pecu", { opacity: 1 }, "<").to("#img-agri", { opacity: 0 }, "<").to(".nav-item-agri", { color: "#fff" }, "<").to(".nav-item-pecu", { color: "#225539" }, "<");

  tl.paused(true);

  gsap.from(".negocio .col-txt", {
    opacity: 0,
    x: -100,
    duration: 1,
    scrollTrigger: {
      trigger: ".negocio",
      start: "top bottom-=200px", // when the top of the trigger hits the top of the
    },
  });

  gsap.from(".negocio .col-img", {
    opacity: 0,
    x: 100,
    duration: 1,
    scrollTrigger: {
      trigger: ".negocio",
      start: "top bottom-=200px", // when the top of the trigger hits the top of the
    },
  });

  gsap.from(".novidades .novidades-grid .item", {
    opacity: 0,
    x: -100,
    duration: 1,
    stagger: 0.1,
    scrollTrigger: {
      trigger: ".novidades",
      start: "top bottom-=200px", // when the top of the trigger hits the top of the
    },
  });

  gsap.from(".novidades .novidades-grid .img", {
    opacity: 0,
    x: 100,
    duration: 1,
    scrollTrigger: {
      trigger: ".novidades",
      start: "top bottom-=200px", // when the top of the trigger hits the top of the
    },
  });

  gsap.from(".familia .txt > *", {
    opacity: 0,
    x: -100,
    duration: 1,
    stagger: 0.1,
    scrollTrigger: {
      trigger: ".familia",
      start: "top bottom-=400px", // when the top of the trigger hits the top of the
    },
  });

  gsap.from(".historia .txt", {
    opacity: 0,
    x: 100,
    duration: 1,
    stagger: 0.1,
    scrollTrigger: {
      trigger: ".historia",
      start: "top bottom-=400px", // when the top of the trigger hits the top of the
    },
  });

}

const quem = document.querySelector(".quem");

if (quem) {
  gsap.set(".quem .col-txt", {
    opacity: 0,
    x: 100,
  });

  gsap.to(".quem .col-txt", {
    opacity: 1,
    x: 0,
    duration: 1,
    stagger: 0.1,
    scrollTrigger: {
      trigger: ".quem",
      start: "top bottom-=400px", // when the top of the trigger hits the top of the
    },
  });

  gsap.from(".estrutura .col-img", {
    opacity: 0,
    x: 100,
    duration: 1,
    scrollTrigger: {
      trigger: ".estrutura",
      start: "top bottom-=200px", // when the top of the trigger hits the top of the
    },
  });

  gsap.from(".estrutura .col-txt", {
    opacity: 0,
    x: -100,
    duration: 1,
    scrollTrigger: {
      trigger: ".estrutura",
      start: "top bottom-=200px", // when the top of the trigger hits the top of the
    },
  });

  gsap.from(".enderecos .item", {
    opacity: 0,
    duration: 1,
    stagger: 0.2,
    scrollTrigger: {
      trigger: ".enderecos",
      start: "top bottom-=200px", // when the top of the trigger hits the top of the
    },
  });

  gsap.from(".historia2 .col-img", {
    opacity: 0,
    x: -100,
    duration: 1,
    scrollTrigger: {
      trigger: ".historia2",
      start: "top bottom-=300px", // when the top of the trigger hits the top of the
    },
  });

  gsap.from(".historia2 .col-txt", {
    opacity: 0,
    x: 100,
    duration: 1,
    scrollTrigger: {
      trigger: ".historia2",
      start: "top bottom-=300px", // when the top of the trigger hits the top of the
    },
  });

  gsap.from(".principios .principio img", {
    opacity: 0,
    duration: 1,
    stagger: 0.2,
    ease: "power1.out",
    scrollTrigger: {
      trigger: ".principios",
      start: "top bottom-=200px", // when the top of the trigger hits the top of the
    },
  });
}

const nossoNegocio = document.querySelector(".nosso-negocio");

if (nossoNegocio) {
  gsap.set(".nosso-negocio .col-txt", {
    opacity: 0,
    x: -100,
  });

  gsap.to(".nosso-negocio .col-txt", {
    opacity: 1,
    x: 0,
    duration: 1,
    stagger: 0.1,
    scrollTrigger: {
      trigger: ".nosso-negocio",
      start: "top bottom-=400px", // when the top of the trigger hits the top of the
    },
  });

  gsap.from(".agricultura .col-img", {
    opacity: 0,
    x: -100,
    duration: 1,
    scrollTrigger: {
      trigger: ".agricultura",
      start: "top bottom-=200px", // when the top of the trigger hits the top of the
    },
  });

  gsap.from(".agricultura .col-txt", {
    opacity: 0,
    x: 100,
    duration: 1,
    scrollTrigger: {
      trigger: ".agricultura",
      start: "top bottom-=200px", // when the top of the trigger hits the top of the
    },
  });

  gsap.from(".agricultura2 .col-img", {
    opacity: 0,
    x: 100,
    duration: 1,
    scrollTrigger: {
      trigger: ".agricultura2",
      start: "top bottom-=200px", // when the top of the trigger hits the top of the
    },
  });

  gsap.from(".agricultura2 .col-txt", {
    opacity: 0,
    x: -100,
    duration: 1,
    scrollTrigger: {
      trigger: ".agricultura2",
      start: "top bottom-=200px", // when the top of the trigger hits the top of the
    },
  });

  gsap.from(".certificadoes-selos img", {
    opacity: 0,
    y: 100,
    duration: 1,
    stagger: 0.1,
    scrollTrigger: {
      trigger: ".certificadoes-selos",
      start: "top bottom-=200px", // when the top of the trigger hits the top of the
    },
  });

  gsap.from(".sustentabilidade .col-img", {
    opacity: 0,
    x: 100,
    duration: 1,
    scrollTrigger: {
      trigger: ".sustentabilidade",
      start: "top bottom-=200px", // when the top of the trigger hits the top of the
    },
  });

  gsap.from(".sustentabilidade .col-txt", {
    opacity: 0,
    x: -100,
    duration: 1,
    scrollTrigger: {
      trigger: ".sustentabilidade",
      start: "top bottom-=200px", // when the top of the trigger hits the top of the
    },
  });

  gsap.from(".agricultura3 .col2", {
    opacity: 0,
    x: 100,
    duration: 1,
    scrollTrigger: {
      trigger: ".agricultura3",
      start: "top bottom-=200px", // when the top of the trigger hits the top of the
    },
  });

  gsap.from(".agricultura3 .col1", {
    opacity: 0,
    x: -100,
    duration: 1,
    scrollTrigger: {
      trigger: ".agricultura3",
      start: "top bottom-=200px", // when the top of the trigger hits the top of the
    },
  });
}

function init(e) {
  // console.log("Engines on.");

  const header = document.querySelector(".main-header");
  const mobileNav = document.querySelector(".mobile-nav");

  const headerH = header.offsetHeight;

  mobileNav.style.top = headerH + "px";

  const btnHamburger = document.querySelector(".btn-hamburger");
  // const btnClose = document.querySelector(".btn-close-nav");
  const nav = document.querySelector(".mobile-nav");

  btnHamburger.addEventListener("click", e => {
    e.preventDefault;
    btnHamburger.classList.toggle("is-active");
    nav.classList.toggle("active");
    // document.body.classList.toggle("no-overflow");
  });
  // btnClose.addEventListener("click", e => {
  //   e.preventDefault;
  //   btnHamburger.classList.toggle("is-active");
  //   nav.classList.toggle("active");
  //   document.body.classList.toggle("no-overflow");
  // });


  const btnSearch = [...document.querySelectorAll(".btn-search")];
  const btnCloseSearch = document.querySelector(".btn-close-search");
  const searchBox = document.querySelector(".search-box");

  if (btnSearch) {
    btnSearch.forEach(el => {
      el.addEventListener("click", e => {
        e.preventDefault;
        searchBox.classList.toggle("active");
      });
    });
    if (btnCloseSearch) {
      btnCloseSearch.addEventListener("click", e => {
        e.preventDefault;
        searchBox.classList.toggle("active");
      });
    }
  }

  const SliderParceiros = document.querySelector('[data-carousel="parceiros"]');

  if (SliderParceiros) {
    const Carousel = new Swiper(SliderParceiros, {
      speed: 400,
      loop: true,
      slidesPerView: 6,
      slidesPerGroup: 1,
      watchOverflow: true,
      spaceBetween: 30,
      autoplay: {
        delay: 3000,
        disableOnInteraction: false,
      },
      navigation: {
        nextEl: document.querySelector(".parcerias-slider .swiper-button-next"),
        prevEl: document.querySelector(".parcerias-slider .swiper-button-prev"),
      },
      breakpoints: {
        640: {
          slidesPerView: 2,
          spaceBetween: 20,
        },
        768: {
          slidesPerView: 4,
          spaceBetween: 40,
        },
        1024: {
          slidesPerView: 5,
          spaceBetween: 50,
        },
      },
    });
  }

  const SliderSingle = document.querySelectorAll('[data-carousel="single-slider"]');
  const sliderSingleNext = document.querySelectorAll(".single-slider .swiper-button-next")
  const sliderSinglePrev = document.querySelectorAll(".single-slider .swiper-button-prev")

  for (i = 0; i < SliderSingle.length; i++) {
    SliderSingle[i].classList.add('single-slider-' + i)
    sliderSingleNext[i].classList.add('swiper-button-next-' + i)
    sliderSinglePrev[i].classList.add('swiper-button-prev-' + i)

    if (SliderSingle[i]) {
      const Carousel = new Swiper(SliderSingle[i], {
        speed: 400,
        // loop: true,
        slidesPerView: 1,
        slidesPerGroup: 1,
        watchOverflow: true,
        spaceBetween: 30,
        navigation: {
          nextEl: document.querySelector(".single-slider .swiper-button-next-" + i),
          prevEl: document.querySelector(".single-slider .swiper-button-prev-" + i),
        },
      });
    }
  }

  // Get modal element
  const overlay = document.querySelector("#overlay");
  const videoModal = document.querySelector(".modal-video");

  const videoBtn = document.querySelector(".thumb-video");

  if (videoBtn) {
    const videoContent = videoModal.querySelector(".videoWrapper");
    const videoClose = videoModal.querySelector(".ico-close");
    videoBtn.addEventListener("click", openModal);
    videoClose.addEventListener("click", closeModal);
    window.addEventListener("click", outsideClick);

    function openModal(e) {
      e.preventDefault();
      const videoId = e.target.dataset.videoid;

      const video = `<iframe width="560" height="315" src="https://www.youtube.com/embed/${videoId}?rel=0" title="YouTube video player"
      frameborder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
      allowfullscreen></iframe>`;

      videoContent.innerHTML = video;

      overlay.classList.add("show");
      videoModal.classList.add("show");
    }

    // Close modal
    function closeModal() {
      overlay.classList.remove("show");
      videoModal.classList.remove("show");
      videoContent.innerHTML = null;
    }

    // Click outside and close
    function outsideClick(e) {
      if (e.target == videoModal) {
        overlay.classList.remove("show");
        videoModal.classList.remove("show");
        videoContent.innerHTML = null;
      }
    }
  }

  function videoDefer() {
    var vidDefer = document.getElementsByTagName("iframe");
    for (var i = 0; i < vidDefer.length; i++) {
      if (vidDefer[i].getAttribute("data-src")) {
        vidDefer[i].setAttribute("src", vidDefer[i].getAttribute("data-src"));
      }
    }
  }

  const catSelect = document.querySelector("#cat-select");

  const baseUrl = window.location.origin;

  console.log(baseUrl);

  if (catSelect) {
    catSelect.addEventListener("change", e => {
      if (e.target.value == 'novidades') {
        const newPlace = baseUrl + "/novidades/";
        window.location.href = newPlace;
      } else {
        const newPlace = baseUrl + "/category/" + e.target.value + "/";
        window.location.href = newPlace;
      }

    });
  }

  // const cookiesBar = document.querySelector(".cookies-bar");
  // const cookiesBtn = document.querySelector(".cookies-bar .btn");

  // cookiesBtn.addEventListener("click", e => {
  //   e.preventDefault();
  //   cookiesBar.classList.add("hide");
  // });

  const boxShare = [...document.querySelectorAll(".box-share")];

  boxShare.forEach(el => {
    const btn = el.querySelector(".btn-share");
    const box = el.querySelector(".share-box");

    btn.addEventListener("click", e => {
      e.preventDefault();
      box.classList.toggle("open");
    });
  });

  /*==MENU ACESSIBILIDADE==*/
  var btA11y = document.querySelector("#a11y .a11y__btn");
  var wrapperA11y = document.getElementById("a11y");
  var btFecharA11y = document.querySelector("#a11y .a11y__close");

  btA11y.addEventListener("click", abrirA11y);
  btFecharA11y.addEventListener("click", fecharA11y);

  function abrirA11y() {
    wrapperA11y.classList.add("active");
  }

  function fecharA11y() {
    wrapperA11y.classList.remove("active");
  }

  // Accessibility

  sessionStorage.getItem("contrast") && +sessionStorage.getItem("contrast") && document.body.classList.add("has--high-contrast");

  if (document.querySelector("[data-contrast]")) {
    document.querySelector("[data-contrast]").addEventListener(
      "click",
      () => {
        console.log("contraste");
        document.body.classList.toggle("has--high-contrast");

        sessionStorage.setItem("contrast", document.body.classList.contains("has--high-contrast") ? 1 : 0);
      },
      true
    );
  }

  if (sessionStorage.getItem("fontsize")) document.body.style.fontSize = sessionStorage.getItem("fontsize") + "px";

  if (document.querySelector("[data-fontsize-decrease]")) {
    document.querySelector("[data-fontsize-decrease]").addEventListener(
      "click",
      event => {
        event.preventDefault();
        let fontsize = parseInt(window.getComputedStyle(document.body, null).getPropertyValue("font-size"));

        if (fontsize > 10) {
          document.body.style.fontSize = --fontsize + "px";
          sessionStorage.setItem("fontsize", fontsize);
        }
      },
      true
    );
  }

  if (document.querySelector("[data-fontsize-increase]")) {
    document.querySelector("[data-fontsize-increase]").addEventListener(
      "click",
      event => {
        event.preventDefault();
        let fontsize = parseInt(window.getComputedStyle(document.body, null).getPropertyValue("font-size"));

        if (fontsize < 12) {
          document.body.style.fontSize = ++fontsize + "px";
          sessionStorage.setItem("fontsize", fontsize);
        }
      },
      true
    );
  }
}

class countUp {
  constructor(el) {
    this.el = el;
    this.setVars();
    this.init();
  }

  setVars() {
    this.number = this.el.querySelectorAll("[data-countup-number]");
    this.observerOptions = { root: null, rootMargin: "0px 0px", threshold: 0 };
    this.observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        const end = parseFloat(entry.target.dataset.countupNumber.replace(/,/g, ""));
        const decimals = this.countDecimals(end);
        if (entry.isIntersecting) {
          this.iterateValue(entry.target, end, decimals);
        }
      });
    }, this.observerOptions);
  }

  init() {
    if (this.number.length > 0) {
      this.number.forEach(el => {
        this.observer.observe(el);
      });
    }
  }

  iterateValue(el, end, decimals) {
    const start = 0;
    const duration = 2500;
    let startTimestamp = null;

    const step = timestamp => {
      if (!startTimestamp) startTimestamp = timestamp;
      const elapsedPercent = (timestamp - startTimestamp) / duration;
      const easedProgress = Math.min(this.easeOutQuint(elapsedPercent), 1);
      let interimNumber = Math.abs(easedProgress * (end - start) + start);
      el.innerHTML = this.formatNumber(interimNumber, decimals);
      if (easedProgress < 1) {
        window.requestAnimationFrame(step);
      }
    };

    // requestAnimationFrame returns DOMHighResTimeStamp as a callback (used as timestamp)
    window.requestAnimationFrame(step);
  }

  easeOutQuad(x) {
    return 1 - Math.pow(1 - x, 3);
  }

  easeOutQuint(x) {
    return 1 - Math.pow(1 - x, 5);
  }

  countDecimals(val) {
    if (Math.floor(val) === val) return 0;
    return val.toString().split(".")[1].length || 0;
  }

  formatNumber(val, decimals) {
    return val.toLocaleString("en-US", {
      minimumFractionDigits: decimals,
      maximumFractionDigits: decimals,
    });
  }
}

// Simplifed version of Viget dynamic modules to attach instances for this demo
// https://www.viget.com/articles/how-does-viget-javascript/
// You CAN use this pattern, but it's single purpose right now
const dataModules = [...document.querySelectorAll('[data-module="countup"]')];

dataModules.forEach(element => {
  element.dataset.module.split(" ").forEach(function () {
    new countUp(element);
  });
});


const blocoVideo = document.querySelector(".home-video");

if (blocoVideo) {
  const hBlocoVideo = blocoVideo.offsetHeight;
  document.addEventListener("scroll", trocaMenu);

  function trocaMenu() {
    var scrollPosition = window.scrollY;
    if (scrollPosition > hBlocoVideo) {
      document.querySelector('.main-header').classList.add('scroll');
    } else {
      document.querySelector('.main-header').classList.remove('scroll');
    }
    console.log(hBlocoVideo);
    console.log(window.scrollY);
  }
}

//copia url 

const currentUrl = window.location.href
const btnCompartilharVaga = document.getElementById('copy-url')

if (btnCompartilharVaga) {
  btnCompartilharVaga.addEventListener('click', () => {
    navigator.clipboard.writeText(currentUrl);
  })
}

const fakeAccordionsParent = document.querySelectorAll('.fale-form .cols .searchandfilter ul')[0]
fakeAccordionsParent.classList.add('accordion', 'v1')

const fakeAccordions = fakeAccordionsParent.querySelectorAll('li:has(ul)')
fakeAccordions.forEach(fakeAccordion => {
  fakeAccordion.classList.add('a-container')
})

const fakeAccordionsBtn = document.querySelectorAll('.a-container > h4')
fakeAccordionsBtn.forEach(fakeAccordionBtn => {
  fakeAccordionBtn.classList.add('a-btn')
})

const fakeAccordionsPanel = document.querySelectorAll('.a-container > ul')
fakeAccordionsPanel.forEach(fakeAccordionPanel => {
  fakeAccordionPanel.classList.add('a-panel')
})

function initAcc(elem, option) {
  document.addEventListener('click', function (e) {
    if (!e.target.matches(elem + ' .a-btn')) return;
    else {
      if (!e.target.parentElement.classList.contains('active')) {
        if (option == true) {
          var elementList = document.querySelectorAll(elem + ' .a-container');
          Array.prototype.forEach.call(elementList, function (e) {
            e.classList.remove('active');
          });
        }
        e.target.parentElement.classList.add('active');
      } else {
        e.target.parentElement.classList.remove('active');
      }
    }
  });
}

initAcc('.accordion.v1', true);